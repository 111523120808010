<template>
  <cube-page
    type="sticky-view-complex"
    title="Sticky"
  >
    <template slot="content">
      <div class="sticky-view-container">
        <cube-sticky
          ref="sticky"
          :pos="scrollY"
          :offset="offsetTop"
          :check-top="false"
          @diff-change="diffChange"
        >
          <cube-scroll
            ref="scroll"
            :data="scrollData"
            :scroll-events="scrollEvents"
            :options="scrollOptions"
            @scroll="scrollHandler"
            @scroll-end="scrollEndHandler"
            @pulling-up="onPullingUp"
            @pulling-down="onPullingDown"
          >
            <slot name="header" />
            <cube-sticky-ele>
              <slot name="sticky-ele" />
            </cube-sticky-ele>
            <slot name="content" />
          </cube-scroll>
          <template slot="fixed">
            <slot name="fied" />
          </template>
        </cube-sticky>
      </div>
    </template>
  </cube-page>
</template>

<script>
import CubePage from '_c/clue-page'
export default {
  components: {
    CubePage
  },
  props: {
    offsetTop: {
      type: Number,
      default: 5
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    loadEnable: {
      type: Boolean,
      default: true
    },
    refreshEnable: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      scrollY: 0,
      scrollEvents: ['scroll', 'scroll-end'],
      pullDownRefreshThreshold: 60,
      pullDownRefreshTxt: '刷新成功',
      // pullUpLoad: true,
      pullUpLoadThreshold: 0,
      pullUpLoadMoreTxt: '更多~',
      pullUpLoadNoMoreTxt: '到底了~',
      upload: false,
      stickyHeadShow: false
    }
  },
  computed: {
    scrollData() {
      return this.data
    },
    stickyPos() {
      return this.$refs.sticky.positions[0] - this.offsetTop
    },
    scrollOptions() {
      return {
        pullDownRefresh: this.pullDownRefreshObj,
        pullUpLoad: this.pullUpLoadObj,
        scrollbar: true,
        directionLockThreshold: 0
      }
    },
    pullDownRefreshObj() {
      return this.refreshEnable
        ? {
          // eslint-disable-next-line radix
          threshold: parseInt(this.pullDownRefreshThreshold),
          txt: this.pullDownRefreshTxt
        }
        : false;
    },
    pullUpLoadObj() {
      return this.loadEnable
        ? {
          // eslint-disable-next-line radix
          threshold: parseInt(this.pullUpLoadThreshold),
          txt: {
            more: this.pullUpLoadMoreTxt,
            noMore: this.pullUpLoadNoMoreTxt
          },
          visible: false
        }
        : false;
    }
  },
  methods: {
    onPullingDown() {
      this.$emit('pullingDown')
    },
    onPullingUp() {
      if (this.upload) {
        this.$emit('pullingUp')
      } else {
        this.$refs.scroll.forceUpdate()
      }
    },
    scrollHandler({ y }) {
      this.scrollY = -y
    },
    scrollEndHandler () {
    },
    diffChange(diff, height) {
      this.$emit('diffChange', diff, height)
    },
    allRefresh() {
      this.$refs.sticky.refresh()
      this.$refs.scroll.refresh()
      this.$refs.scroll.scrollTo(0, 0)
      this.scrollY = 0
    },
    scrollRefresh() {
      this.$refs.scroll.refresh()
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-view-complex {
  height: 100%;
  .sticky-view-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    .sticky-header {
      background-color: #666;
    }
    .cube-sticky {
      // padding: 0 10px;
      .cube-scroll-wrapper {
        background-color: #fff;
      }
    }
    .cube-sticky-ele-on {
      visibility: hidden;
    }
    .cube-sticky-fixed {
      .sticky-header {
        margin: 0 10px;
        background-color: transparent;
      }
      .cube-tab-bar {
        margin: 0 10px;
        background-color: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, .1);
      }
    }
  }
}
</style>
